import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import InitiativeList from "./initiative-list-home";
import Icon from "./icon";

import * as styles from "../styles/components/home-initiatives.module.css";

const Initiatives = (props) => {

  const {
    heading,
    _rawDescription,
    link,
    initiatives
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {heading && <h2 className={cn(styles.heading, "h3")}>{heading}</h2>}
        {initiatives && <InitiativeList items={initiatives} />}
        {(_rawDescription || (link && link.linkUrl)) && (
          <div className={styles.content} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            <div className="containerContent">
              {_rawDescription && <PortableText blocks={_rawDescription} />}
              {link && link.linkUrl && <Link to={link.linkUrl} className={cn(styles.link, "linkArrowRight")}>{link.text}<Icon symbol="arrow-right" /></Link>}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default Initiatives;

import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Helmet from 'react-helmet';

import PageBanner from "../components/page-banner";
import FeaturedBlocks from "../components/featured-blocks";
import Initiatives from "../components/home-initiatives";
import Facts from "../components/home-facts";
import About from "../components/home-about";
import NextPage from "../components/next-page";

import * as styles from "../styles/components/home.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: sanityHome {
      pageBanner {
        text
        anchorLinkText
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
      featuredBlocks {
        _key
        _rawText
        rightAligned
        featuredImage
        image {
          ...SanityImage
          alt
          caption
        }
        link {
          text
          linkUrl
        }
      }
      homeInitiatives {
        heading
        _rawDescription
        link {
          text
          linkUrl
        }
        initiatives {
          _key
          initiative {
            title
            featuredImage {
              ...SanityImage
              alt
            }
            excerpt
            slug {
              current
            }
          }
        }
      }
      homeFacts {
        heading
        facts {
          _key
          number
          description
        }
      }
      homeAbout {
        heading
        _rawDescription
        link {
          linkUrl
          text
        }
        image {
          ...SanityImage
          alt
        }
      }
      nextPage {
        link {
          linkUrl
          text
        }
        _rawDescription
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Helmet>
        <body className={styles.root} />
      </Helmet>
      <Seo
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {home.pageBanner && <PageBanner {...home.pageBanner} />}

      <div id="main">

        {home.featuredBlocks && <FeaturedBlocks items={home.featuredBlocks} />}

        {home.homeInitiatives && <Initiatives {...home.homeInitiatives} />}

        {home.homeFacts && <Facts {...home.homeFacts} />}

        {home.homeAbout && <About {...home.homeAbout} />}

        {home.nextPage && <NextPage {...home.nextPage} />}

      </div>
    </Layout>
  );
};

export default IndexPage;

import React from "react";
import Slider from "react-slick";
import FactItem from "./fact-item";

import * as styles from "../styles/components/fact-list.module.css";

function FactList({ items }) {

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={styles.root} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <Slider {...settings}>
        {items.map(({ _key, number, description }, index) => {
          return (
            <FactItem key={_key} number={number} description={description} index={index} />
          );
        })}
      </Slider>
    </div>
  );
}

export default FactList;

import React from "react";
import { Link } from "gatsby";
import { getInitiativeUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/initiative-list-home.module.css";

function InitiativeList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ initiative, _key }) => {
        return (
          <li className={styles.item} key={_key}>
            {initiative.title && (
              <div className={styles.title} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                <div className="containerContent">
                  <h3 className="h2">
                    {initiative.slug 
                      ? <Link className={styles.link} to={getInitiativeUrl(initiative.slug.current)}>{initiative.title}</Link> 
                      : initiative.title
                    }
                  </h3>
                </div>
              </div>
            )}
            {(initiative.excerpt || initiative.slug) && (
              <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                <div className="containerContent">
                  {initiative.excerpt && <p>{initiative.excerpt}</p>}
                  {initiative.slug && <Link className="btn" to={getInitiativeUrl(initiative.slug.current)}>Learn More</Link>}
                </div>
              </div>
            )}
            {initiative.featuredImage && initiative.featuredImage.asset && (
              <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                {initiative.slug 
                  ? <Link className={styles.link} to={getInitiativeUrl(initiative.slug.current)}><img src={imageUrlFor(buildImageObj(initiative.featuredImage)).auto("format").url()} alt={initiative.featuredImage.alt} /></Link> 
                  : <img src={imageUrlFor(buildImageObj(initiative.featuredImage)).auto("format").url()} alt={initiative.featuredImage.alt} />
                }
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default InitiativeList;

import React, { useState } from "react";
import { cn } from "../lib/helpers";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import * as styles from "../styles/components/fact-item.module.css";

const FactItem = (props) => {

  const {
    key, 
    number, 
    description,
    index
  } = props;

  const [visible, setVisible] = useState(false);

  function onChangeVisibility (isVisible) {
    setVisible(isVisible);
  }

  const easingFn = (t, b, c, d) => {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  };

  return (
    <div key={key} className={styles.root}>
      <VisibilitySensor
        onChange={onChangeVisibility} 
        active={!visible}
      >
        {({ isVisible }) => (
          <div className="containerContent">
            {number && (
              <div className={cn(styles.number, "h1")}>
                {isVisible && <CountUp end={number} duration={1.5} easingFn={easingFn} suffix={(index === 0 || index === 2) ? "+" : ""} />}
              </div>
            )}
            {description && <p>{description}</p>}
          </div>
        )} 
      </VisibilitySensor>
    </div>
  );
}

export default FactItem;

import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";
import Icon from "./icon";

import * as styles from "../styles/components/home-about.module.css";

const About = (props) => {

  const {
    heading,
    _rawDescription,
    link,
    image
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {image && image.asset && (
          <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          </div>
        )}
        {(heading || _rawDescription || (link && link.linkUrl)) && (
          <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            <div className="containerContent">
              {heading && <h2 className="h3">{heading}</h2>}
              {_rawDescription && <PortableText blocks={_rawDescription} />}
              {link && link.linkUrl && <Link to={link.linkUrl} className="linkArrowRight">{link.text}<Icon symbol="arrow-right" /></Link>}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default About;
